import TableCell from '@mui/material/TableCell';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import EuroPrice from 'components/EuroPrice';
import InvoiceRowPaymentButton from 'components/InvoiceRowPaymentButton';
import { locale } from 'const';
import { format } from 'date-fns';
import { ParentInvoiceListView } from 'schema';

type Props = TableRowProps & {
  invoice: ParentInvoiceListView;
  noBorder?: boolean;
};

const InvoiceRow = ({ invoice, noBorder = false, ...rest }: Props) => (
  <TableRow {...rest}>
    <TableCell sx={[noBorder && { borderBottom: 0 }]}>
      {format(new Date(invoice.period), "yyyy 'm.' LLLL", { locale })}
    </TableCell>

    <TableCell sx={[noBorder && { borderBottom: 0 }]}>
      <EuroPrice>{invoice.total}</EuroPrice>
    </TableCell>

    <TableCell sx={[{ textAlign: 'right' }, noBorder && { borderBottom: 0 }]}>
      <InvoiceRowPaymentButton invoice={invoice} />
    </TableCell>
  </TableRow>
);

export default InvoiceRow;
