import { useCallback, MouseEvent } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import EuroIcon from '@mui/icons-material/Euro';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { ParentInvoiceListView } from 'schema';
import {
  useInvoiceStartPayment,
  useInvoiceStartTokenPayment,
} from 'features/Invoice';
import { useMe } from 'features/Auth';

type Props = {
  invoice: ParentInvoiceListView;
};

const InvoiceRowPaymentButton = ({ invoice }: Props) => {
  const { id, token, status } = invoice;
  const startPayment = useInvoiceStartPayment(id);
  const startTokenPayment = useInvoiceStartTokenPayment(token);
  const me = useMe();

  const handlePay = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (me.data) {
        startPayment.mutate();
      } else {
        startTokenPayment.mutate();
      }
    },
    [me, startPayment, startTokenPayment]
  );

  if (status === 'paid') {
    return (
      <Button
        disabled
        variant="contained"
        size="small"
        startIcon={<DoneIcon />}>
        Apmokėta
      </Button>
    );
  }

  return (
    <LoadingButton
      variant="contained"
      size="small"
      startIcon={<EuroIcon />}
      loading={startPayment.isPending || startTokenPayment.isPending}
      onClick={handlePay}>
      Mokėti
    </LoadingButton>
  );
};

export default InvoiceRowPaymentButton;
