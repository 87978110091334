import DeleteIcon from '@mui/icons-material/Delete';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import UserAvatar from 'components/UserAvatar';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import AttendandEditDialog from 'components/AttendandEditDialog';

import { AttendantListView } from 'schema';
import { useDeleteAttendant } from 'features/Attendant';

import { useDialog } from 'components/Dialog';

type Props = {
  attendant: AttendantListView;
};

const AttendantListItem = ({ attendant }: Props) => {
  const { id, fullName } = attendant;
  const destroy = useDeleteAttendant(id);
  const { openDialog, ...dialogProps } = useDialog();

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={openDialog}>
          <ListItemIcon>
            <UserAvatar user={attendant} />
          </ListItemIcon>

          <ListItemText>{fullName}</ListItemText>
        </ListItemButton>

        <ListItemSecondaryAction>
          <ConfirmableIconButton
            text={`Ar tikrai norite ištrinti ${fullName}?`}
            onConfirm={destroy.mutate}>
            <DeleteIcon />
          </ConfirmableIconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <AttendandEditDialog attendant={attendant} {...dialogProps} />
    </>
  );
};

export default AttendantListItem;
