import { ReactNode } from 'react';

import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import TransitionUp from 'components/TransitionUp';
import TransitionRight from 'components/TransitionRight';
import BottomToolbar from 'components/BottomToolbar';
import ResponsiveDialogMobileTitle, {
  ResponsiveDialogMobileTitleProps,
} from 'components/ResponsiveDialogMobileTitle';
import ResponsiveDialogMobileContent from 'components/ResponsiveDialogMobileContent';

export type DialogVariant = 'close' | 'back';

type Props = Omit<MuiDialogProps, 'title'> &
  ResponsiveDialogMobileTitleProps & {
    title: ReactNode;
    variant?: DialogVariant;
    actions?: ReactNode;
  };

export type ResponsiveDialogMobileProps = Props;

const ResponsiveDialogMobile = (props: Props) => {
  const {
    onClose,
    title,
    children,
    variant = 'close',
    avatar,
    actions,
    ...rest
  } = props;

  return (
    <MuiDialog
      fullScreen
      onClose={onClose}
      TransitionComponent={variant === 'close' ? TransitionUp : TransitionRight}
      {...rest}>
      <ResponsiveDialogMobileTitle
        onClose={onClose}
        variant={variant}
        avatar={avatar}>
        {title}
      </ResponsiveDialogMobileTitle>

      <ResponsiveDialogMobileContent>{children}</ResponsiveDialogMobileContent>
      {actions && <BottomToolbar>{actions}</BottomToolbar>}
    </MuiDialog>
  );
};

export default ResponsiveDialogMobile;
