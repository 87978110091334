import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AttendantAvatarList from 'components/AttendantAvatarList';

import { hash } from 'lib';
import { useDialog } from 'components/Dialog';
import { DayScheduleSessionView } from 'schema';

import {
  amber,
  blue,
  cyan,
  green,
  indigo,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import SessionDetailsDialog from 'components/SessionDetailsDialog';

type Props = {
  session: DayScheduleSessionView;
};

const colors = [
  red[400],
  pink[400],
  purple[400],
  indigo[400],
  blue[400],
  cyan[400],
  teal[400],
  green[400],
  lime[400],
  yellow[600],
  amber[400],
  orange[400],
];

const TimetableScheduleSession = ({ session }: Props) => {
  const { openDialog, ...dialog } = useDialog();

  return (
    <>
      <Paper
        sx={{
          p: 1,
          mb: 1,
          bgcolor: colors[hash(session.id.toString(), colors.length)],
          color: '#ffffff',
          '&:last-child': { mb: 0 },
        }}
        onClick={openDialog}>
        <Box>{session.activity.name}</Box>

        <AttendantAvatarList
          attendants={session.attendantRegistrations.map(
            ({ attendant }) => attendant
          )}
        />
      </Paper>

      <SessionDetailsDialog {...dialog} session={session} />
    </>
  );
};

export default TimetableScheduleSession;
