import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';

import { useIsDesktop } from 'lib';

type Props = LoadingButtonProps;

const ResponsiveDialogButton = (props: Props) => {
  const isDesktop = useIsDesktop();

  return isDesktop ? (
    <LoadingButton {...props} />
  ) : (
    <LoadingButton fullWidth variant="contained" size="large" {...props} />
  );
};

export default ResponsiveDialogButton;
