import ResponsiveDialogDesktop, {
  ResponsiveDialogDesktopProps,
} from 'components/ResponsiveDialogDesktop';

import ResponsiveDialogMobile, {
  ResponsiveDialogMobileProps,
} from 'components/ResponsiveDialogMobile';

import { useIsDesktop } from 'lib';

type Props = ResponsiveDialogMobileProps | ResponsiveDialogDesktopProps;

export type ResponsiveDialogProps = Props;

const ResponsiveDialog = (props: Props) => {
  const isDesktop = useIsDesktop();

  return isDesktop ? (
    <ResponsiveDialogDesktop {...props} />
  ) : (
    <ResponsiveDialogMobile {...props} />
  );
};

export default ResponsiveDialog;
