import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';

type Props = DialogContentProps;

const ResponsiveDialogDesktopContent = ({ sx = [], ...props }: Props) => (
  <DialogContent
    sx={[{ bgcolor: '#f9fafb' }, ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}
  />
);

export default ResponsiveDialogDesktopContent;
