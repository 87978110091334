import { useCallback } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import FormResponsiveDialog from 'components/FormResponsiveDialog';
import ResponsiveDialogButton from 'components/ResponsiveDialogButton';
import SessionAbsenceForm, {
  Fields,
  schema,
} from 'components/SessionAbsenceForm';

import { useUpdateAbsence } from 'features/AttendantRegistrations';
import { ResponsiveDialogProps } from 'components/ResponsiveDialog';
import { MySessionRegistrationDetails, SessionAbsenceDetails } from 'schema';

type Props = Omit<ResponsiveDialogProps, 'title'> & {
  registration: MySessionRegistrationDetails;
  absence: SessionAbsenceDetails;
};

const EditSessionAbsenceDialog = (props: Props) => {
  const { registration, absence, onClose, ...rest } = props;
  const update = useUpdateAbsence(registration.id, absence.id);

  const handleSubmit = useCallback(
    ({ start, end, ...rest }: Fields) => {
      const form = {
        start: start!,
        end: end!,
        ...rest,
      };
      update.mutate(form, {
        onSuccess: onClose,
      });
    },
    [onClose, update]
  );

  return (
    <FormResponsiveDialog
      {...rest}
      onClose={onClose}
      variant="back"
      title="Sirgimas"
      form={{
        initialValues: absence,
        onSubmit: handleSubmit,
        validationSchema: schema,
      }}
      actions={
        <ResponsiveDialogButton
          type="submit"
          loading={update.isPending}
          startIcon={<SaveIcon />}>
          Saugoti
        </ResponsiveDialogButton>
      }>
      <SessionAbsenceForm
        minDate={registration.absenceMinDate}
        maxDate={registration.absenceMaxDate}
      />
    </FormResponsiveDialog>
  );
};

export default EditSessionAbsenceDialog;
