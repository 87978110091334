import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns/format';

import Grid from '@mui/material/Unstable_Grid2';
import Card, { CardProps } from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import NoImage from 'components/NoImage';
import SessionRegistrationDetailsDialog from 'components/SessionRegistrationDetailsDialog';

import { DATE_TIME_FORMAT } from 'const';
import { romanWeek } from 'lib';
import { MySessionRegistrationListItem } from 'schema';

type Props = CardProps & {
  registration: MySessionRegistrationListItem;
};

const SessionRegistrationCard = ({ registration, sx = [], ...rest }: Props) => {
  const { id } = useParams();
  const { attendant, session } = registration;
  const { activity, weekSchedule, shift } = session;
  const navigate = useNavigate();
  const open = registration.id === Number(id);

  const handleClose = useCallback(() => {
    navigate('/sessions');
  }, [navigate]);

  return (
    <>
      <Card
        elevation={0}
        sx={[{ display: 'flex' }, ...(Array.isArray(sx) ? sx : [sx])]}
        {...rest}>
        <CardActionArea
          href={registration.id.toString()}
          sx={{ display: 'flex', width: '100%' }}>
          <Grid container sx={{ width: '100%' }}>
            <Grid xs={4}>
              {activity.photo ? (
                <CardMedia
                  component="img"
                  image={activity.photo.publicUrl}
                  sx={{ width: '100%', height: 135 }}
                  alt={activity.name}
                />
              ) : (
                <NoImage sx={{ width: '100%', height: 135 }} />
              )}
            </Grid>

            <Grid xs={8}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom>{activity.name}</Typography>

                {activity.type === 'regular' ? (
                  <Typography color="text.secondary">
                    {weekSchedule
                      ?.map(
                        item => `${romanWeek(item.weekDay)}: ${item.startTime}`
                      )
                      .join(', ')}
                  </Typography>
                ) : (
                  <Typography color="text.secondary">
                    {format(shift.start, DATE_TIME_FORMAT)}
                  </Typography>
                )}

                <Typography color="text.secondary">
                  {attendant.fullName}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>

      <SessionRegistrationDetailsDialog
        open={open}
        onClose={handleClose}
        registration={registration}
      />
    </>
  );
};

export default SessionRegistrationCard;
