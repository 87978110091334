import ReceiptIcon from '@mui/icons-material/Receipt';
import Typography from '@mui/material/Typography';
import Blank from 'components/Blank';
import InvoiceTable from 'components/InvoiceTable';
import InvoiceRowSkeleton from 'components/InvoiceRowSkeleton';
import InvoiceRowWithDetails from 'components/InvoiceRowWithDetails';
import PaymentStatus from 'components/PaymentStatus';

import { useMyInvoices } from 'features/Invoice';

const Invoices = () => {
  const invoicesQuery = useMyInvoices();

  if (!invoicesQuery.data)
    return (
      <InvoiceTable>
        <InvoiceRowSkeleton />
        <InvoiceRowSkeleton />
      </InvoiceTable>
    );

  const invoices = invoicesQuery.data;

  if (invoices.length > 0) {
    return (
      <>
        <PaymentStatus />

        <InvoiceTable>
          {invoices.map(invoice => (
            <InvoiceRowWithDetails invoice={invoice} key={invoice.id} />
          ))}
        </InvoiceTable>
      </>
    );
  }

  return (
    <Blank>
      <ReceiptIcon fontSize="inherit" />
      <Typography>Sąskaitų dar nėra</Typography>
    </Blank>
  );
};

export default Invoices;
