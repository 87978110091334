import { format } from 'date-fns/format';

import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Link from '@mui/material/Link';

import { ParentInvoiceListView } from 'schema';
import { useDownload } from 'lib';
import EuroPrice from 'components/EuroPrice';

type Props = {
  open: boolean;
  invoice: ParentInvoiceListView;
};

const colSpan = 3;

const InvoiceRowDetails = ({ open, invoice }: Props) => {
  const { items, total, paymentDate, status } = invoice;

  const [download, isDownloading] = useDownload(
    `/api/v1/invoices/${invoice.id}/pdf`
  );

  return (
    <Collapse in={open} unmountOnExit>
      <Box>
        Sąskaita:{' '}
        <Link onClick={download}>
          {isDownloading ? 'Palaukite...' : invoice.invoiceNumber}
        </Link>
      </Box>

      {paymentDate && status !== 'paid' && (
        <Box>Apmokėti iki: {format(paymentDate, 'yyyy-MM-dd')}</Box>
      )}

      <Table
        size="small"
        aria-label="purchases"
        sx={{
          '& td': { pl: 0 },
          '& th': { pl: 0 },
        }}>
        <TableHead>
          <TableRow>
            <TableCell component="th">Paslauga</TableCell>

            <TableCell component="th" align="center">
              Kiekis
            </TableCell>

            <TableCell component="th" align="center">
              Kaina
            </TableCell>

            <TableCell component="th" align="right">
              Suma
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items.map((row, i) => (
            <TableRow key={i}>
              <TableCell>
                <Box>{row.name}</Box>

                <Box component="div" color="text.secondary">
                  {row.attendants
                    .map(
                      ({ firstName, lastName }) =>
                        `${firstName} ${lastName.charAt(0)}.`
                    )
                    .join(', ')}
                </Box>
              </TableCell>

              <TableCell align="center">{row.amount}</TableCell>

              <TableCell align="center">
                <EuroPrice>{row.price}</EuroPrice>
              </TableCell>

              <TableCell align="right">
                <EuroPrice>{row.total}</EuroPrice>
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell align="right" colSpan={colSpan} sx={{ borderBottom: 0 }}>
              <strong>Viso</strong>
            </TableCell>

            <TableCell align="right" sx={{ borderBottom: 0 }}>
              <EuroPrice sx={{ fontWeight: 'bold' }}>{total}</EuroPrice>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Collapse>
  );
};

export default InvoiceRowDetails;
