import { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import FullScreen from 'components/FullScreen';
import AuthLogo from 'components/AuthLogo';
import FormTextField from 'components/FormTextField';

import { useLogin } from 'features/Auth';
import { useAuth } from 'components/AuthGuard';
import { useIsDesktop } from 'lib';

type Fields = {
  email: string;
  password: string;
};

const initial = {
  email: '',
  password: '',
};

const schema = object({
  email: string().required('Prašome įvesti el pašto adresą'),
  password: string().required('Prašome įvesti slaptažodį'),
});

const AuthLogin = () => {
  const [message, setMessage] = useState<string | null>(null);
  const desktop = useIsDesktop();
  const login = useLogin();
  const { recover } = useAuth();

  const handleSubmit = useCallback(
    (values: Fields) => {
      setMessage(null);

      login.mutate(values, {
        onError: (error: any) => {
          const message = error?.response?.data?.message;

          if (message) {
            setMessage(message);
          }
        },

        onSuccess: () => {
          window.location.reload();
        },
      });
    },
    [login]
  );

  return (
    <FullScreen sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: desktop ? '500px' : '100%' }}>
        <AuthLogo title="Prisijunkite prie savitarnos" />

        {message && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {message}
          </Alert>
        )}

        <Formik<Fields>
          initialValues={initial}
          onSubmit={handleSubmit}
          validationSchema={schema}>
          <Form>
            <Stack spacing={4}>
              <FormTextField
                name="email"
                label="El. paštas"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <FormTextField
                name="password"
                type="password"
                label="Slaptažodis"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <LoadingButton
                loading={login.isPending}
                variant="contained"
                type="submit">
                Prisijungti
              </LoadingButton>

              <Box sx={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Link onClick={recover}>Pamiršote slaptažodį?</Link>
                </Box>
              </Box>
            </Stack>
          </Form>
        </Formik>
      </Box>
    </FullScreen>
  );
};

export default AuthLogin;
