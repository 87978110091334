import Box, { BoxProps } from '@mui/material/Box';

type Props = BoxProps & {
  children: number;
};

const EuroPrice = ({ children, sx = [], ...props }: Props) => {
  console.log(children);

  return (
    children && (
      <Box
        component="span"
        sx={[{ whiteSpace: 'nowrap' }, ...(Array.isArray(sx) ? sx : [sx])]}
        {...props}>
        {children.toFixed(2)} €
      </Box>
    )
  );
};

export default EuroPrice;
