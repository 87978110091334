import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SessionRegistrationCard from 'components/SessionRegistrationCard';
import MySessionsSkeleton from 'components/MySessionsSkeleton';

import { useMyRegistrations } from 'features/AttendantRegistrations';

const MySessions = () => {
  const myRegistrations = useMyRegistrations();

  if (!myRegistrations.data) return <MySessionsSkeleton />;

  const registrations = myRegistrations.data;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Lankomi būreliai
      </Typography>

      {registrations.length > 0 ? (
        registrations.map((registration, i) => (
          <SessionRegistrationCard
            key={i}
            registration={registration}
            sx={{ mb: 2 }}
          />
        ))
      ) : (
        <Box>Dar nelankote jokių būrelių.</Box>
      )}
    </Box>
  );
};

export default MySessions;
