import Dialog, { DialogProps } from 'components/Dialog';
import SessionDetails from 'components/SessionDetails';
import DialogButton from 'components/DialogButton';

import { AttendantInvitationListView } from 'schema';
import { APP_URL } from 'const';

type Session = {
  id: number;
  activity: {
    name: string;
  };
};

type Props = Omit<DialogProps, 'children' | 'title' | 'id'> & {
  session: Session;
  invitation?: AttendantInvitationListView;
};

const SessionDetailsDialog = (props: Props) => {
  const { session, invitation, ...rest } = props;
  const { id, activity } = session;

  return (
    <>
      <Dialog {...rest} title={activity.name}>
        <SessionDetails id={id} />

        {invitation && (
          <DialogButton href={`${APP_URL}${invitation.link}`}>
            Registracija
          </DialogButton>
        )}
      </Dialog>
    </>
  );
};

export default SessionDetailsDialog;
