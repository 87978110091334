import { ActivityType } from 'schema';

export function activityType(type: ActivityType): string {
  const typeLookup: Record<ActivityType, string> = {
    camp: 'Stovykla',
    regular: 'Būrelis',
  };

  return typeLookup[type];
}
