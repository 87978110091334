import { Form, Formik, FormikConfig, FormikValues } from 'formik';

import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ResponsiveDialogDesktopTitle from 'components/ResponsiveDialogDesktopTitle';
import ResponsiveDialogDesktopContent from 'components/ResponsiveDialogDesktopContent';

import { ResponsiveDialogDesktopProps } from 'components/ResponsiveDialogDesktop';

type Props<F extends FormikValues> = ResponsiveDialogDesktopProps & {
  form: FormikConfig<F>;
};

export type FormResponsiveDialogDesktopProps<F extends FormikValues> = Props<F>;

function FormResponsiveDialogDesktop<F extends FormikValues>(props: Props<F>) {
  const { onClose, title, avatar, children, actions, form, ...rest } = props;

  return (
    <MuiDialog onClose={onClose} scroll="body" {...rest}>
      <Formik<F> {...form}>
        <Form>
          <ResponsiveDialogDesktopTitle avatar={avatar} onClose={onClose}>
            {title}
          </ResponsiveDialogDesktopTitle>

          <ResponsiveDialogDesktopContent>
            {children}
          </ResponsiveDialogDesktopContent>

          {actions && <DialogActions>{actions}</DialogActions>}
        </Form>
      </Formik>
    </MuiDialog>
  );
}

export default FormResponsiveDialogDesktop;
