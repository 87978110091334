import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import NoImage from 'components/NoImage';
import Skeleton from '@mui/material/Skeleton';

const SessionRegistrationCardSkeleton = ({ sx = [], ...props }: CardProps) => (
  <Card
    elevation={0}
    sx={[{ display: 'flex' }, ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}>
    <NoImage width={200} height={125} />

    <CardContent sx={{ flexGrow: 1 }}>
      <Typography gutterBottom>
        <Skeleton sx={{ width: '55%' }} />
      </Typography>

      <Typography color="text.secondary">
        <Skeleton sx={{ width: '35%' }} />
      </Typography>

      <Typography color="text.secondary">
        <Skeleton sx={{ width: '45%' }} />
      </Typography>
    </CardContent>
  </Card>
);

export default SessionRegistrationCardSkeleton;
