import { ReactNode } from 'react';

import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ResponsiveDialogDesktopTitle, {
  ResponsiveDialogDesktopTitleProps,
} from 'components/ResponsiveDialogDesktopTitle';
import ResponsiveDialogDesktopContent from 'components/ResponsiveDialogDesktopContent';

type Props = Omit<MuiDialogProps, 'title'> &
  ResponsiveDialogDesktopTitleProps & {
    title: ReactNode;
    actions?: ReactNode;
  };

export type ResponsiveDialogDesktopProps = Props;

const ResponsiveDialogDesktop = (props: Props) => {
  const { onClose, title, avatar, children, actions, ...rest } = props;

  return (
    <MuiDialog onClose={onClose} scroll="body" {...rest}>
      <ResponsiveDialogDesktopTitle avatar={avatar} onClose={onClose}>
        {title}
      </ResponsiveDialogDesktopTitle>

      <ResponsiveDialogDesktopContent>
        {children}
      </ResponsiveDialogDesktopContent>

      {actions && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  );
};

export default ResponsiveDialogDesktop;
