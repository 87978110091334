import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useDialog } from 'components/Dialog';
import EditSessionAbsenceDialog from 'components/EditSessionAbsenceDialog';
import EuroPrice from 'components/EuroPrice';
import { formatDate } from 'lib';

import {
  MySessionRegistrationDetails,
  SessionAbsenceDetails,
  SessionAbsenceStatus,
} from 'schema';

type Props = {
  registration: MySessionRegistrationDetails;
  absence: SessionAbsenceDetails;
};

const statusLookup: Record<SessionAbsenceStatus, string> = {
  submitted: 'Neperžiūrėta',
  rejected: 'Atmesta',
  approved: 'Išskaičiuota',
  refunded: 'Grąžinta',
};

const SessionRegistrationDetailsAbsence = (props: Props) => {
  const { registration, absence } = props;
  const { start, end, comment, status, approvedDiscount, adminComment } =
    absence;
  const { openDialog, ...dialog } = useDialog();

  return (
    <Box>
      <Box>
        Nuo {formatDate(start)} iki {formatDate(end)}
      </Box>

      <Box>Globėjas: {comment}</Box>
      <Box>
        Būklė: {statusLookup[status]}
        {(status === 'approved' || status === 'refunded') && (
          <>
            {' '}
            <EuroPrice>{approvedDiscount ?? 0}</EuroPrice>
          </>
        )}
        {status === 'rejected' && `: ${adminComment}`}
      </Box>

      {status === 'submitted' && (
        <Box sx={{ textAlign: 'right' }}>
          <Button startIcon={<EditIcon />} onClick={openDialog}>
            Redaguoti
          </Button>
        </Box>
      )}

      <EditSessionAbsenceDialog
        registration={registration}
        absence={absence}
        {...dialog}
      />
    </Box>
  );
};

export default SessionRegistrationDetailsAbsence;
