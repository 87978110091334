import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import BottomToolbar from 'components/BottomToolbar';

type Props = LoadingButtonProps;

/** @deprecated Replace with ResponsiveDialogButton */
const DialogButton = (props: Props) => (
  <BottomToolbar>
    <LoadingButton fullWidth variant="contained" size="large" {...props} />
  </BottomToolbar>
);

export default DialogButton;
