import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useNotification } from 'components/Notifications';
import { useErrorHandler } from 'lib';
import {
  attendantRegistrationIndex,
  attendantRegistrationShow,
  AttendantRegistrationShowInput,
  attendantRegistrationSubmitAbsence,
  attendantRegistrationUpdateAbsence,
  MySessionRegistrationDetails,
  SubmitAbsenceForm,
} from 'schema';

export const attendantRegistrationsKeys = {
  all: [{ scope: 'attendant-registrations' }] as const,

  lists: () =>
    [{ ...attendantRegistrationsKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...attendantRegistrationsKeys.lists()[0] }] as const,

  details: () =>
    [{ ...attendantRegistrationsKeys.all[0], entity: 'detail' }] as const,
  detail: (params: AttendantRegistrationShowInput) =>
    [{ ...attendantRegistrationsKeys.details()[0], params }] as const,
};

type AttendantRegistrationDetailsContext = QueryFunctionContext<
  ReturnType<(typeof attendantRegistrationsKeys)['detail']>
>;

export const useMyRegistrations = () =>
  useQuery({
    queryKey: attendantRegistrationsKeys.list(),
    queryFn: attendantRegistrationIndex,
  });

export const useRegistration = (registration: number) =>
  useQuery({
    queryKey: attendantRegistrationsKeys.detail({ registration }),
    queryFn: async ({
      queryKey: [{ params }],
    }: AttendantRegistrationDetailsContext) =>
      await attendantRegistrationShow(params),
  });

export const useSubmitAbsence = (registration: number) => {
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: SubmitAbsenceForm) =>
      attendantRegistrationSubmitAbsence({ registration, form }),

    onSuccess: absence => {
      client.setQueryData<MySessionRegistrationDetails>(
        attendantRegistrationsKeys.detail({ registration }),

        registration => {
          if (!registration) return;

          const { absences, ...rest } = registration;

          return {
            ...rest,
            absences: [absence, ...absences],
          };
        }
      );

      pop(
        'Sirgimas buvo užregistruotas, susisieksime su jumis dėl sprendimo apie kompensaciją'
      );
    },

    onError: useErrorHandler(),
  });
};

export const useUpdateAbsence = (registration: number, absence: number) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (form: SubmitAbsenceForm) =>
      attendantRegistrationUpdateAbsence({ registration, absence, form }),

    onSuccess: absence => {
      client.setQueryData<MySessionRegistrationDetails>(
        attendantRegistrationsKeys.detail({ registration }),

        registration => {
          if (!registration) return;

          const { absences, ...rest } = registration;

          return {
            ...rest,
            absences: absences.map(item =>
              item.id === absence.id ? absence : item
            ),
          };
        }
      );
    },

    onError: useErrorHandler(),
  });
};
