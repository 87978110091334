import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { createDebugger } from '@igudo/debugger';

import * as Sentry from '@sentry/react';
import App from 'App';

import { version } from 'version';
import { ENV, SENTRY_DSN, STORAGE_ACCESS_TOKEN } from 'const';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@igudo/debugger/dist/styles.css';

if (ENV !== 'production') {
  createDebugger();
}

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    release: version,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const params = new URLSearchParams(window.location.search);
const token = params.get('accessToken');

if (token) {
  localStorage.setItem(STORAGE_ACCESS_TOKEN, token);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
