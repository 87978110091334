import Paper, { PaperProps } from '@mui/material/Paper';

type Props = PaperProps & {
  noPadding?: boolean;
  noMargin?: boolean;
};

const DetailsSection = (props: Props) => {
  const { sx = [], noPadding = false, noMargin = false, ...rest } = props;

  return (
    <Paper
      sx={[
        !noMargin && { m: 2 },
        !noPadding && { p: 2 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      elevation={0}
      {...rest}
    />
  );
};

export default DetailsSection;
