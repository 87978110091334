import EmailIcon from '@mui/icons-material/Email';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import PersonIcon from '@mui/icons-material/Person';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';

import logo from 'assets/img/logo.png';
import { useNavValue } from 'lib';

const NavDesktop = () => {
  const value = useNavValue();

  return (
    <AppBar
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&.MuiAppBar-root': {
          bgcolor: '#ffffff',
        },
      }}>
      <Toolbar sx={{ width: 800 }}>
        <Box
          sx={{
            flexGrow: 1,
            '& img': {
              height: 35,
            },
          }}>
          <Link href="/">
            <img src={logo} alt="iGudo" />
          </Link>
        </Box>

        <Tabs value={value}>
          <Tab href="/" label="Tvarkaraštis" icon={<CalendarMonthIcon />} />
          <Tab href="/invoices" label="Sąskaitos" icon={<ReceiptIcon />} />
          <Tab href="/messages" label="Pranešimai" icon={<EmailIcon />} />
          <Tab href="/sessions" label="Būreliai" icon={<BeachAccessIcon />} />
          <Tab href="/account" label="Paskyra" icon={<PersonIcon />} />
        </Tabs>
      </Toolbar>
    </AppBar>
  );
};

export default NavDesktop;
