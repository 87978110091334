import AssignmentIcon from '@mui/icons-material/Assignment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Blank from 'components/Blank';
import SessionResultsResult from 'components/SessionResultsResult';
import { useIsDesktop } from 'lib';

import { MySessionRegistrationDetails } from 'schema';

type Props = {
  registration: MySessionRegistrationDetails;
};

const SessionResults = ({ registration }: Props) => {
  const isDesktop = useIsDesktop();

  return (
    <Box sx={[{ p: 1 }, !isDesktop && { minHeight: 'calc(100vh - 176px)' }]}>
      {registration.results.length > 0 ? (
        registration.results.map(result => (
          <SessionResultsResult key={result.id} result={result} />
        ))
      ) : (
        <Blank offset={192}>
          <AssignmentIcon fontSize="inherit" />
          <Typography>Rezultatų dar nėra</Typography>
        </Blank>
      )}
    </Box>
  );
};

export default SessionResults;
