import SessionDetailsSkeleton from 'components/SessionDetailsSkeleton';
import SessionRegistrationDetails from 'components/SessionRegistrationDetails';
import SessionResults from 'components/SessionResults';
import TabPanel from 'components/TabPanel';

import { useRegistration } from 'features/AttendantRegistrations';

type Props = {
  id: number;
  selectedPanel: number;
};

const SessionRegistrationDetailsDialogContent = (props: Props) => {
  const { id, selectedPanel } = props;
  const details = useRegistration(id);

  if (!details.data)
    return (
      <TabPanel value={selectedPanel} index={0}>
        <SessionDetailsSkeleton />
      </TabPanel>
    );

  return (
    <>
      <TabPanel value={selectedPanel} index={0}>
        <SessionRegistrationDetails registration={details.data} />
      </TabPanel>

      <TabPanel value={selectedPanel} index={1}>
        <SessionResults registration={details.data} />
      </TabPanel>
    </>
  );
};

export default SessionRegistrationDetailsDialogContent;
