import { date, object, ref, string } from 'yup';

import FormDatePicker from 'components/FormDatePicker';
import FormTextField from 'components/FormTextField';
import Stack from 'components/Stack';
import TwoColumn from 'components/TwoColumn';

import { useIsDesktop } from 'lib';

export type Fields = {
  start: Date | null;
  end: Date | null;
  comment: string;
};

export type Props = {
  minDate: Date | null;
  maxDate: Date;
};

export const schema = object({
  start: date().nullable().required('Prašome pasirinkti pradžios datą'),
  end: date()
    .nullable()
    .required('Prašome pasirinkti pabaigos datą')
    .min(ref('start'), 'Pabaigos data negali būti anksčiau nei pradžia'),
  comment: string().required('Prašome įvesti komentarą'),
});

const SessionAbsenceForm = ({ minDate, maxDate }: Props) => (
  <Stack
    sx={[
      { p: 2, height: '100%' },
      !useIsDesktop() && { minHeight: 'calc(100vh - 176px)' },
    ]}>
    <TwoColumn
      leftSlot={
        <FormDatePicker
          name="start"
          label="Nuo"
          minDate={minDate ?? undefined}
          maxDate={maxDate}
        />
      }
      rightSlot={
        <FormDatePicker
          name="end"
          label="Iki"
          minDate={minDate ?? undefined}
          maxDate={maxDate}
        />
      }
    />

    <FormTextField multiline name="comment" label="Komentaras" />
  </Stack>
);

export default SessionAbsenceForm;
