import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import DetailsSection from 'components/DetailsSection';
import SessionRegistrationDetailsAbsences from 'components/SessionRegistrationDetailsAbsences';
import UserAvatar from 'components/UserAvatar';

import { formatDate, useDownload, weekDayName } from 'lib';
import { MySessionRegistrationDetails } from 'schema';

type Props = {
  registration: MySessionRegistrationDetails;
};

const SessionRegistrationDetails = ({ registration }: Props) => {
  const { id, startDate, session, hasAgreement } = registration;
  const { shift, weekDaySchedule, activity, coach, address, price } = session;
  const { club } = activity;

  const [download, isDownloading] = useDownload(
    `/api/v1/viewer/attendant-registrations/${id}/agreement`
  );

  return (
    <Box sx={{ p: 1 }}>
      <Typography sx={{ fontWeight: 'bold' }}>Tvarkaraštis</Typography>

      <DetailsSection noMargin sx={{ mb: 2 }}>
        {activity.type === 'regular' ? (
          <>
            <Grid container>
              {weekDaySchedule.map(schedule => (
                <>
                  <Grid xs={6}>
                    <Typography color="text.secondary">
                      {weekDayName(schedule.weekDay)}:
                    </Typography>
                  </Grid>

                  <Grid xs={6}>
                    {schedule.startTime}
                    {schedule.endTime && ` - ${schedule.endTime}`}
                  </Grid>
                </>
              ))}
            </Grid>

            <Divider sx={{ mb: 1, mt: 1 }} />

            <Grid container>
              <Grid xs={6}>
                <Typography color="text.secondary">Vyksta iki:</Typography>
              </Grid>

              {shift.end && <Grid xs={6}>{formatDate(shift.end)}</Grid>}
            </Grid>
          </>
        ) : (
          <Grid container>
            <Grid xs={6}>
              <Typography color="text.secondary">Pradžia:</Typography>
            </Grid>

            <Grid xs={6}>{formatDate(shift.start)}</Grid>

            {shift.end && (
              <>
                <Grid xs={6}>
                  <Typography color="text.secondary">Pabaiga:</Typography>
                </Grid>

                <Grid xs={6}>{formatDate(shift.end)}</Grid>
              </>
            )}
          </Grid>
        )}
      </DetailsSection>

      <Typography sx={{ fontWeight: 'bold' }}>Treneris</Typography>

      <DetailsSection noMargin sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography>{coach.user.fullName}</Typography>
            <Typography color="text.secondary">{club.name}</Typography>
          </Box>

          <UserAvatar user={coach.user} />
        </Box>

        <Divider sx={{ mb: 1, mt: 1 }} />

        <Grid container>
          {coach.user.phone && (
            <>
              <Grid xs={6}>
                <Typography color="text.secondary">Telefonas:</Typography>
              </Grid>

              <Grid xs={6}>{coach.user.phone}</Grid>
            </>
          )}

          <Grid xs={6}>
            <Typography color="text.secondary">El. paštas:</Typography>
          </Grid>

          <Grid xs={6}>{coach.user.email}</Grid>

          {address && (
            <>
              <Grid xs={6}>
                <Typography color="text.secondary">Vieta:</Typography>
              </Grid>

              <Grid xs={6}>{address}</Grid>
            </>
          )}
        </Grid>
      </DetailsSection>

      <Typography sx={{ fontWeight: 'bold' }}>Sutartis</Typography>

      <DetailsSection noMargin sx={{ mb: 2 }}>
        <Grid container>
          <Grid xs={6}>
            <Typography color="text.secondary">Lanko nuo:</Typography>
          </Grid>

          <Grid xs={6}>{formatDate(startDate)}</Grid>

          <Grid xs={6}>
            <Typography color="text.secondary">Kaina:</Typography>
          </Grid>

          <Grid xs={6}>
            {price} €{activity.type === 'regular' && '/mėn'}
          </Grid>

          {hasAgreement && (
            <>
              <Grid xs={6}>
                <Typography color="text.secondary">Sutartis:</Typography>
              </Grid>

              <Grid xs={6}>
                <Link onClick={download}>
                  {isDownloading ? 'Palaukite...' : 'Parsisiųsti'}
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </DetailsSection>

      <Typography sx={{ fontWeight: 'bold' }}>Sirgimai</Typography>
      <SessionRegistrationDetailsAbsences registration={registration} />
    </Box>
  );
};

export default SessionRegistrationDetails;
