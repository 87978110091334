import { Form, Formik, FormikConfig, FormikValues } from 'formik';

import MuiDialog from '@mui/material/Dialog';
import TransitionUp from 'components/TransitionUp';
import TransitionRight from 'components/TransitionRight';
import BottomToolbar from 'components/BottomToolbar';
import ResponsiveDialogMobileTitle from 'components/ResponsiveDialogMobileTitle';
import ResponsiveDialogMobileContent from 'components/ResponsiveDialogMobileContent';

import { ResponsiveDialogMobileProps } from 'components/ResponsiveDialogMobile';

type Props<F extends FormikValues> = ResponsiveDialogMobileProps & {
  form: FormikConfig<F>;
};

export type FormResponsiveDialogMobileProps<F extends FormikValues> = Props<F>;

function FormResponsiveDialogMobile<F extends FormikValues>(props: Props<F>) {
  const {
    onClose,
    title,
    children,
    variant = 'close',
    avatar,
    actions,
    form,
    ...rest
  } = props;

  return (
    <MuiDialog
      fullScreen
      onClose={onClose}
      TransitionComponent={variant === 'close' ? TransitionUp : TransitionRight}
      {...rest}>
      <Formik<F> {...form}>
        <Form>
          <ResponsiveDialogMobileTitle
            onClose={onClose}
            variant={variant}
            avatar={avatar}>
            {title}
          </ResponsiveDialogMobileTitle>

          <ResponsiveDialogMobileContent>
            {children}
          </ResponsiveDialogMobileContent>
          {actions && <BottomToolbar>{actions}</BottomToolbar>}
        </Form>
      </Formik>
    </MuiDialog>
  );
}

export default FormResponsiveDialogMobile;
