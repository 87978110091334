import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import {
  sessionIndex,
  SessionIndexParams,
  sessionShow,
  SessionShowInput,
} from 'schema';

export const sessionKeys = {
  all: [{ scope: 'session' }] as const,

  lists: () => [{ ...sessionKeys.all[0], entity: 'list' }] as const,
  list: (params: SessionIndexParams) =>
    [{ ...sessionKeys.lists()[0], params }] as const,

  details: () => [{ ...sessionKeys.all[0], entity: 'detail' }] as const,
  detail: (params: SessionShowInput) =>
    [{ ...sessionKeys.details()[0], params }] as const,
};

type SessionListContext = QueryFunctionContext<
  ReturnType<(typeof sessionKeys)['list']>
>;

type SessionDetailsContext = QueryFunctionContext<
  ReturnType<(typeof sessionKeys)['detail']>
>;

export const useSessions = (params: SessionIndexParams) =>
  useQuery({
    queryKey: sessionKeys.list(params),
    queryFn: async ({ queryKey: [{ params }] }: SessionListContext) =>
      await sessionIndex({ params }),
  });

export const useSession = (session: number) =>
  useQuery({
    queryKey: sessionKeys.detail({
      session,
    }),

    queryFn: async ({ queryKey: [{ params }] }: SessionDetailsContext) =>
      await sessionShow(params),
  });
