import { useCallback, useState } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import InvoiceRow from 'components/InvoiceRow';
import InvoiceRowDetails from 'components/InvoiceRowDetails';

import { ParentInvoiceListView } from 'schema';

type Props = {
  invoice: ParentInvoiceListView;
  defaultOpen?: boolean;
};

const InvoiceRowWithDetails = ({ invoice, defaultOpen = false }: Props) => {
  const [open, setOpen] = useState(
    window.location.hash.replace('#', '') === invoice.invoiceNumber ||
      defaultOpen
  );

  const handleClick = useCallback(() => {
    setOpen(v => !v);
  }, []);

  return (
    <>
      <InvoiceRow noBorder={open} invoice={invoice} onClick={handleClick} />

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <InvoiceRowDetails open={open} invoice={invoice} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default InvoiceRowWithDetails;
