import { ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { DialogVariant } from 'components/ResponsiveDialogMobile';

type Props = {
  onClose: () => void;
  children?: ReactNode;
  variant?: DialogVariant;
  avatar?: ReactNode;
};

export type ResponsiveDialogMobileTitleProps = Props;

const ResponsiveDialogMobileTitle = (props: Props) => {
  const { variant = 'close', avatar, children, onClose } = props;

  return (
    <AppBar sx={{ position: 'fixed' }}>
      <Toolbar>
        {variant === 'back' && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close">
            <ArrowBackIcon />
          </IconButton>
        )}

        {avatar}

        <Typography
          sx={{ ml: 2, flex: 1, lineHeight: 1 }}
          variant="h6"
          component="div">
          {children}
        </Typography>

        {variant === 'close' && (
          <IconButton edge="start" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveDialogMobileTitle;
