import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DetailsSection from 'components/DetailsSection';
import UserAvatar from 'components/UserAvatar';
import SessionDetailsSchedule from 'components/SessionDetailsSchedule';
import SessionDetailsSkeleton from 'components/SessionDetailsSkeleton';

import { useSession } from 'features/Session';

type Props = {
  id: number;
};

const SessionDetails = ({ id }: Props) => {
  const query = useSession(id);

  if (!query.data) return <SessionDetailsSkeleton />;

  const { name, coach, price, address, activity } = query.data;
  const { user, club } = coach;

  return (
    <Box>
      {activity.photo && (
        <Paper
          square
          sx={{
            width: '100%',
            height: '240px',
            '& img': { width: '100%', height: '240px', objectFit: 'cover' },
          }}>
          <img src={activity.photo.publicUrl} alt={name} />
        </Paper>
      )}

      <DetailsSection sx={{ display: 'flex', alignItems: 'center' }}>
        <UserAvatar user={user} />

        <Box sx={{ ml: 2 }}>
          <Typography variant="h6">
            {user.firstName} {user.lastName}
          </Typography>

          <Typography color="text.secondary">{club.name}</Typography>
          <Typography color="text.secondary">{user.phone}</Typography>
          <Typography color="text.secondary">{user.email}</Typography>
        </Box>
      </DetailsSection>

      <DetailsSection noPadding>
        <SessionDetailsSchedule session={query.data} />
        <Divider />

        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Kaina</Typography>
          <Typography color="text.secondary">
            {price.toFixed(2)} €/mėn
          </Typography>
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Vieta</Typography>
          <Typography color="text.secondary">{address}</Typography>
        </Box>
      </DetailsSection>

      <DetailsSection>
        <Typography sx={{ fontWeight: 'bold' }}>Aprašymas</Typography>
        <Typography>{activity.description}</Typography>
      </DetailsSection>
    </Box>
  );
};

export default SessionDetails;
