import { FormikValues } from 'formik';

import FormResponsiveDialogDesktop, {
  FormResponsiveDialogDesktopProps,
} from 'components/FormResponsiveDialogDesktop';

import { useIsDesktop } from 'lib';
import FormResponsiveDialogMobile, {
  FormResponsiveDialogMobileProps,
} from 'components/FormResponsiveDialogMobile';

type Props<F extends FormikValues> =
  | FormResponsiveDialogMobileProps<F>
  | FormResponsiveDialogDesktopProps<F>;

export type FormResponsiveDialogProps<F extends FormikValues> = Props<F>;

function FormResponsiveDialog<T extends FormikValues>(props: Props<T>) {
  const isDesktop = useIsDesktop();

  return isDesktop ? (
    <FormResponsiveDialogDesktop {...props} />
  ) : (
    <FormResponsiveDialogMobile {...props} />
  );
}

export default FormResponsiveDialog;
