import { SyntheticEvent, useCallback, useState } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ResponsiveDialog, {
  ResponsiveDialogProps,
} from 'components/ResponsiveDialog';
import SessionRegistrationDetailsDialogContent from 'components/SessionRegistrationDetailsDialogContent';
import UserAvatar from 'components/UserAvatar';

import { MySessionRegistrationListItem } from 'schema';
import { activityType } from 'lib';

type Props = Omit<ResponsiveDialogProps, 'children' | 'title'> & {
  registration: MySessionRegistrationListItem;
};

const SessionRegistrationDetailsDialog = ({ registration, ...rest }: Props) => {
  const { id, session, attendant } = registration;
  const { activity } = session;

  const [value, setValue] = useState(0);

  const handleChange = useCallback((_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }, []);

  return (
    <ResponsiveDialog
      {...rest}
      title={activity.name}
      avatar={<UserAvatar user={attendant} />}>
      <Tabs value={value} onChange={handleChange} variant="fullWidth">
        <Tab label={activityType(activity.type)} />
        <Tab label="Rezultatai" />
      </Tabs>

      <SessionRegistrationDetailsDialogContent selectedPanel={value} id={id} />
    </ResponsiveDialog>
  );
};

export default SessionRegistrationDetailsDialog;
