import { useParams } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import AppSplash from 'components/AppSplash';
import InvoiceDetails from 'components/InvoiceDetails';

import { useIsDesktop } from 'lib';
import { useInvoice } from 'features/Invoice';

import logo from 'assets/img/logo.png';

const InvoicePayment = () => {
  const { token } = useParams();
  const isDesktop = useIsDesktop();
  const invoice = useInvoice(token!);

  return (
    <Box
      sx={[
        {
          bgcolor: '#f9fafb',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 9,
        },
      ]}>
      <Box sx={{ width: isDesktop ? 800 : '100%' }}>
        <AppBar
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '&.MuiAppBar-root': {
              bgcolor: '#ffffff',
            },
          }}>
          <Toolbar sx={{ width: isDesktop ? 800 : '100%' }}>
            <Box
              sx={{
                flexGrow: 1,
                '& img': {
                  height: 35,
                },
              }}>
              <Link href="/">
                <img src={logo} alt="iGudo" />
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
        {invoice.data ? (
          <InvoiceDetails invoice={invoice.data} />
        ) : (
          <AppSplash />
        )}
      </Box>
    </Box>
  );
};

export default InvoicePayment;
