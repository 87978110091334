import { createBrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Root from 'routes/Root';
import ErrorPage from 'routes/ErrorPage';
import Messages from 'routes/Messages';
import Invoices from 'routes/Invoices';
import Timetable from 'routes/Timetable';
import Sessions from 'routes/Sessions';
import Account from 'routes/Account';
import InvoicePayment from 'routes/InvoicePayment';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/invoice/:token',
    element: <InvoicePayment />,
  },
  {
    path: '/',
    errorElement: <ErrorPage />,
    element: <Root />,
    children: [
      {
        index: true,
        element: <Timetable />,
      },
      {
        path: 'invoices',
        element: <Invoices />,
      },
      {
        path: 'messages',
        element: <Messages />,
      },
      {
        path: 'sessions',
        element: <Sessions />,
        children: [
          {
            path: ':id',
            element: <Sessions />,
          },
        ],
      },
      {
        path: 'account',
        element: <Account />,
      },
    ],
  },
]);
