import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  invoiceMyInvoices,
  invoiceShow,
  InvoiceShowInput,
  invoiceStartPayment,
  invoiceStartTokenPayment,
  ParentInvoiceListView,
  paymentCancel,
  paymentConfirm,
  PaymentPayload,
} from 'schema';

export const invoiceKeys = {
  all: [{ scope: 'invoices' }] as const,

  lists: () => [{ ...invoiceKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...invoiceKeys.lists()[0] }] as const,

  details: () => [{ ...invoiceKeys.all[0], entity: 'details' }] as const,
  detail: (params: InvoiceShowInput) =>
    [{ ...invoiceKeys.lists()[0], params }] as const,
};

type InvoiceDetailsContext = QueryFunctionContext<
  ReturnType<(typeof invoiceKeys)['detail']>
>;

export const useMyInvoices = () =>
  useQuery({
    queryKey: invoiceKeys.list(),
    queryFn: invoiceMyInvoices,
    refetchInterval: 2000,
  });

export const useInvoice = (token: string) =>
  useQuery({
    queryKey: invoiceKeys.detail({ token }),
    queryFn: async ({ queryKey: [{ params }] }: InvoiceDetailsContext) =>
      await invoiceShow(params),
  });

export const useInvoiceStartPayment = (invoice: number) =>
  useMutation({
    mutationFn: () => invoiceStartPayment({ invoice }),

    onSuccess: ({ redirectUrl }) => {
      window.location.replace(redirectUrl);
    },
  });

export const useInvoiceStartTokenPayment = (token: string) =>
  useMutation({
    mutationFn: () => invoiceStartTokenPayment({ token }),

    onSuccess: ({ redirectUrl }) => {
      window.location.replace(redirectUrl);
    },
  });

export const useConfirmPayment = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (form: PaymentPayload) => paymentConfirm({ form }),

    onSuccess: invoice => {
      client.invalidateQueries({
        queryKey: invoiceKeys.detail({ token: invoice.token }),
      });

      client.setQueryData<ParentInvoiceListView[]>(
        invoiceKeys.list(),
        current =>
          current?.map(item => (item.id === invoice.id ? invoice : item)) ?? []
      );
    },
  });
};

export const useCancelPayment = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (form: PaymentPayload) => paymentCancel({ form }),

    onSuccess: invoice => {
      client.invalidateQueries({
        queryKey: invoiceKeys.detail({ token: invoice.token }),
      });

      client.setQueryData<ParentInvoiceListView[]>(
        invoiceKeys.list(),
        current =>
          current?.map(item => (item.id === invoice.id ? invoice : item)) ?? []
      );
    },
  });
};
