import Box, { BoxProps } from '@mui/material/Box';
import ImageIcon from '@mui/icons-material/Image';

type Props = BoxProps;

const NoImage = ({ sx = [], ...props }: Props) => (
  <Box
    sx={[
      {
        height: 125,
        width: 200,
        bgcolor: '#f5f5f5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'text.secondary',
        fontSize: '72px',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}>
    <ImageIcon fontSize="inherit" />
  </Box>
);

export default NoImage;
