import { ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

type Props = {
  children?: ReactNode;
  onClose: () => void;
  avatar?: ReactNode;
};

export type ResponsiveDialogDesktopTitleProps = Props;

const ResponsiveDialogDesktopTitle = ({ avatar, children, onClose }: Props) => (
  <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
    {avatar && <Box sx={{ mr: 1 }}>{avatar}</Box>}
    <Box sx={{ flexGrow: 1 }}>{children}</Box>

    <IconButton color="inherit" onClick={onClose}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>
);

export default ResponsiveDialogDesktopTitle;
